import styled, { css } from 'styled-components';
import { PageLayout } from 'src/ui/common/layouts/page-layout';
import { Typography } from 'src/ui/common/atoms/typography';
import { media } from 'src/ui/common/mixins/media';
import { FlexBox } from 'src/ui/common/atoms/flex-box';

export const StyledLayout = styled(PageLayout)`
  display: flex;
  margin: 140px 0 140px;
  padding: 0 10px;
  align-items: flex-start;

  ${media.sm(css`
    margin-left: 40px;
    margin-right: 40px;
  `)}
  
  ${media.md(css`
    align-items: center;
    margin: 90px auto;
  `)}
  
  ${media.lg(css`
    padding: 0;
  `)}
`;

export const StyledTitle= styled(Typography)`
  margin-bottom: 10px;
`;

export const StyledActions = styled(FlexBox)`
  margin-top: 40px;
`;
