import React from 'react';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Custom500 from 'src/features/common/500';
import { PAGES_LOCALES } from 'src/constants/common.constants';

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
    props: {
        ...(await serverSideTranslations(locale ?? 'en', PAGES_LOCALES)),
    },
});

function Error() {
    return <Custom500 />;
}

export default Error;
