import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { useScreenDetect } from 'src/hooks/useScreenDetect';
import PageMeta from 'src/components/organisms/page-meta';
import { Button } from 'src/ui/common/atoms/button';
import { Typography } from 'src/ui/common/atoms/typography';
import { RouteE } from 'src/constants/pages.constants';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { StyledActions, StyledLayout, StyledTitle } from './styles';

export default function Custom500() {
    const { t } = useTranslation(['pages', 'actions']);
    const screen = useScreenDetect();

    return (
        <>
            <PageMeta title={t('500.title', { ns: 'pages' })} />
            <StyledLayout>
                <FlexBox flexDirection='column'>
                    <StyledTitle
                        color='white'
                        tag='div'
                        variant='title'
                        size='base'
                        sizeSm='md'
                        sizeMd='xl'
                        medium
                    >
                        {t('500.title', { ns: 'pages' })}
                    </StyledTitle>
                    <Typography size='xs' sizeSm='sm' tag='div'>
                        {t('500.description', { ns: 'pages' })}
                    </Typography>
                    <StyledActions>
                        <Link href={RouteE.HOME}>
                            <a>
                                <Button variant='white' size={screen.xs ? 'md' : 'lg'}>
                                    {t('navigation.goToHomepage', { ns: 'actions' })}
                                </Button>
                            </a>
                        </Link>
                    </StyledActions>
                </FlexBox>
            </StyledLayout>
        </>
    );
}

